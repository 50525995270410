import React, { useEffect, useState } from "react";
import "../Signin.scss";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "../Shared/Header";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { resetTemplate } from "../../redux/actions/template";
const heading = "BROADCAST TEMPLATES";
const NewTemplate = () => {
  const location = useLocation();
  const history = useNavigate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dailyTemplates, setDailyTemplates] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [weeklyTemplates, setWeeklyTemplates] = useState([]);
  const [venueName, setVenueName] = useState("");
  const [setVenueId] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId");
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No access token found in localStorage");
          return;
        }
        const response = await axios.get(
          requests.getTemplate + "?" + `venueId=${venueId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          const data = response.data.data;
          setDailyTemplates(data.daily || []);
          setWeeklyTemplates(data.weekly || []);
          if (data.daily.length > 0) {
            setVenueName(data.daily[0].venueName);
            setVenueId(data.daily[0].venueId);
          }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "/";
          } else {
            console.error("Error response:", error.response.data);
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    };
    fetchTemplates();
  }, []);

  const formatTime = (time) => {
    if (!time || time.hour === null || time.minute === null) {
      return "N/A";
    }
    const { hour, minute } = time;
    const formattedHour = hour % 12 || 12;
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedMinute = minute.toString().padStart(2, "0");
    return `${formattedHour}:${formattedMinute} ${ampm}`;
  };

  const systemSchedule = () => {
    dispatch(resetTemplate([]));
    const venueId = searchParams.get("venueId") || "defaultVenueId";
    history(`/WeeklyTemplate?venueId=${venueId}`, { state: location.state });
  };
  const handleDayClick = (day = "defaultDay") => {
    const venueId = searchParams.get("venueId") || "defaultVenueId";
    history(`/DailyTemplate?venueId=${venueId}`, {
      state: {
        detail: location?.state?.detail,
        day,
      },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleEdit = (day = "defaultDay") => {
    const venueId = searchParams.get("venueId") || "defaultVenueId";
    if (selectedTemplate) {
      navigate(
        `/EditDailyTemplate?venueId=${venueId}&templateId=${selectedTemplate.id}`
      );
    } else {
      console.error("No template selected for editing");
    }
  };
  const handleEditWeeklyTemplate = (day = "defaultDay") => {
    const venueId = searchParams.get("venueId") || "defaultVenueId";
    if (selectedTemplate) {
      navigate(
        `/EditWeeklyTemplate?venueId=${venueId}&templateId=${selectedTemplate.id}`
      );
    } else {
      console.error("No template selected for editing");
    }
  };
  const handleClick = (event, template) => {
    setSelectedTemplate(template);
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event, template) => {
    setSelectedTemplate(template);
    setAnchorEl1(event.currentTarget);
  };

  const handleDeleteConfirmation = () => {
    setOpenConfirmDialog(true);
    setAnchorEl(null);
  };
  const handleDeleteConfirmation1 = () => {
    setOpenConfirmDialog(true);
    setAnchorEl1(null);
  };
  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${requests.deleteTemplate}/${selectedTemplate.id}`
      );

      if (response.data.success) {
        setDailyTemplates(
          dailyTemplates.filter(
            (template) => template.id !== selectedTemplate.id
          )
        );
        setWeeklyTemplates(
          weeklyTemplates.filter(
            (template) => template.id !== selectedTemplate.id
          )
        );
        setSuccessMessage(response.data.message);
      }
      setOpenConfirmDialog(false);
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            fullWidth={true}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header menuList={[]} heading={heading} location={{}} />

            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
              <div className="customer-detail">
                <Typography className="detail">
                  <b>Venue ID :</b> {venueId}
                </Typography>
                <Typography className="detail">{venueName}</Typography>
              </div>

              {/* Daily Templates */}
              <div className="template-section">
                <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 10 }}>
                  <b>Daily Templates</b>
                </Typography>
                <Button
                  variant="contained"
                  className="button"
                  onClick={() => handleDayClick()}
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "100%",
                    mb: 1,
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  }}
                >
                  <b>Create Daily Template</b>
                </Button>
                {dailyTemplates.map((template) => (
                  <Button
                    key={template.id}
                    className="template-button"
                    variant="contained"
                    sx={{
                      background: "linear-gradient(#808184, #404142)",
                      color: "white",
                      width: "100%",
                      mb: 1,
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      "&:hover": {
                        background: "linear-gradient(#808184, #404142)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexGrow: 1,
                      }}
                      onClick={() =>
                        navigate(
                          `/EditDailyBroadcast?templateId=${template.id}&venueId=${venueId}`
                        )
                      }
                    >
                      {template.scheduleTemplateDetailDTOS?.map(
                        (detail, index) => {
                          if (
                            detail.startTimeAndEndTimeDTOS &&
                            detail.startTimeAndEndTimeDTOS.length > 0
                          ) {
                            const timeSlot = detail.startTimeAndEndTimeDTOS[0];
                            return (
                              <span key={index}>
                                {formatTime(timeSlot.startTime)} -{" "}
                                {formatTime(timeSlot.endTime)}
                              </span>
                            );
                          }
                          return <span key={index}>No time available</span>;
                        }
                      )}
                    </Box>

                    <IconButton
                      onClick={(event) => handleClick(event, template)}
                      sx={{ color: "white" }}
                    >
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </IconButton>
                  </Button>
                ))}

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
                </Menu>
              </div>
              <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  DELETE TEMPLATE
                </DialogTitle>
                <DialogContent
                  sx={{
                    textAlign: "center",
                    color: "gray",
                    fontSize: "0.9rem",
                  }}
                >
                  This action can't be undone
                </DialogContent>
                <DialogActions
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={handleCancelDelete}
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      await handleConfirmDelete();
                      setOpenConfirmDialog(false);
                    }}
                    variant="contained"
                    color="error"
                    fullWidth
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={Boolean(successMessage)}
                onClose={() => setSuccessMessage("")}
                PaperProps={{
                  sx: {
                    borderRadius: "8px",
                    padding: "16px",
                    width: "500px",
                    textAlign: "center",
                  },
                }}
              >
                <DialogTitle
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    marginBottom: "-30px",
                  }}
                >
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    <b>{successMessage}</b>
                  </Typography>
                </DialogTitle>
                <DialogContent
                  sx={{
                    textAlign: "center",
                    color: "gray",
                    fontSize: "1rem",
                    marginBottom: "16px",
                  }}
                />
                <DialogActions
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => setSuccessMessage("")}
                    variant="contained"
                    color="error"
                    fullWidth
                    sx={{
                      backgroundColor: "red",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      "&:hover": {
                        backgroundColor: "darkred",
                      },
                    }}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Weekly Templates */}
              <div className="template-section">
                <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 10 }}>
                  <b>Weekly Templates</b>
                </Typography>
                <Button
                  variant="contained"
                  className="button"
                  onClick={() => systemSchedule()}
                >
                  Create Weekly Template
                </Button>
                {weeklyTemplates.map((template) => (
                  <Button
                    key={template.id}
                    className="template-button"
                    variant="contained"
                    sx={{
                      background: "linear-gradient(#808184, #404142)",
                      color: "white",
                      width: "100%",
                      // mb: 1,
                      mt: 1,
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      "&:hover": {
                        background: "linear-gradient(#808184, #404142)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexGrow: 1,
                      }}
                      onClick={() =>
                        navigate(
                          `/WeeklyBroadcast?templateId=${template.id}&venueId=${venueId}`
                        )
                      }
                    >
                      {template.templateName}
                    </Box>
                    <IconButton
                      onClick={(event) => handleClick1(event, template)}
                      sx={{ color: "white" }}
                    >
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl1}
                      open={Boolean(anchorEl1)}
                      onClose={handleClose1}
                    >
                      <MenuItem onClick={handleEditWeeklyTemplate}>
                        Edit
                      </MenuItem>
                      <MenuItem onClick={handleDeleteConfirmation1}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </Button>
                ))}
              </div>
            </Box>
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default NewTemplate;