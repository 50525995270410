import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  Button,
  ButtonBase,
  Grid,
  TextField,
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Header from "../Shared/Header";
import { weekdays } from "../../constants/constants";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import { useDispatch, useSelector } from "react-redux";

const WeeklyTemplate = ({ classes }) => {
  const [settingId, setSettingId] = useState(0);
  const [activeStatus, setActiveStatus] = useState(false);
  const [weekdaySetting, setWeekdaySetting] = useState(weekdays);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [openDialog, setOpenDialog] = useState(false); // State for dialog
  const history = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId") || "defaultVenueId";
  const timeData = location?.state?.timeData;
  const dispatch = useDispatch();
  const weekTemplateData = useSelector((state) => state.template);
  console.log(weekTemplateData);
  const updatedTimeData = location?.state?.template;

  useEffect(() => {
    if (updatedTimeData) {
      // Handle updated time data logic if necessary
    }
  }, [updatedTimeData]);

  const convertTo24HourFormat = (time) => {
    if (!time) return { hour: 0, minute: 0 }; // Default value

    // If time is already in object format, return it
    if (
      typeof time === "object" &&
      time.hour !== undefined &&
      time.minute !== undefined
    ) {
      return time;
    }

    if (typeof time !== "string") {
      console.error("Invalid time format:", time);
      return { hour: 0, minute: 0 };
    }

    const [hours, minutes] = time.split(":");
    const period = minutes.slice(-2);
    const minutesOnly = minutes.slice(0, 2);

    let hour = parseInt(hours, 10);
    const minute = parseInt(minutesOnly, 10);

    if (period === "AM" && hour === 12) {
      hour = 0;
    } else if (period === "PM" && hour !== 12) {
      hour += 12;
    }

    return { hour, minute };
  };

  const handleSave = async () => {
    if (!templateName.trim()) {
      setErrorDialogOpen(true);
      return;
    }

    const scheduleTemplateDetailDTOS = weekdaySetting.map((day) => {
      const templateDay = weekTemplateData?.find(
        (template) => template.dayOfWeek === day.weekday.toUpperCase()
      );

      const gameTimes =
        templateDay?.startTimeAndEndTimeDTOS?.map((time, index) => ({
          id: index,
          startTime: convertTo24HourFormat(time.startTime), // Ensure valid input
          endTime: convertTo24HourFormat(time.endTime),
        })) || [];

      return {
        dayOfWeek: day.weekday.toUpperCase(),
        id: day.id,
        off: gameTimes.length === 0,
        startTimeAndEndTimeDTOS: gameTimes,
      };
    });

    const dto = {
      scheduleTemplateDetailDTOS,
      templateName,
      type: "weekly",
      venueId,
    };

    try {
      const res = await axios.post(requests.createWeeklyTemplate, dto);
      console.log("Save Successful:", res.data);
      setOpenDialog(true);
    } catch (error) {
      console.log("Error saving template:", error.message);
    }
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  const handleDayClick = (day) => {
    history(`/Customer/WeeklySet?venueId=${venueId}`, {
      state: {
        detail: location?.state?.detail,
        day: day,
      },
    });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    history(`/BroadcastTemplate?venueId=${venueId}`); // Redirect after dialog close
  };
  const formatTime = (hour, minute) => {
    const period = hour < 12 ? "AM" : "PM";
    const displayHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${displayHour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} ${period}`;
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };

  const menuList = [
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header menuList={menuList} heading={"WEEKLY TEMPLATE"} />
            <Box
              component="form"
              style={classes.boxWidth}
              noValidate
              sx={{ width: "100%", mt: 1 }}
            >
              <div className="customer-detail">
                <Typography className="detail">
                  <b>Venue ID :</b> {venueId}
                </Typography>
              </div>
              <Typography className="details" align="center" sx={{ my: 1 }}>
                <b>Create Weekly Template</b>
              </Typography>
              <Box
                sx={{ border: 1, borderRadius: "1rem", borderColor: "gray" }}
              >
                <List>
                  {weekdaySetting?.map((day) => {
                    const templateDay = weekTemplateData?.find(
                      (template) =>
                        template.dayOfWeek === day.weekday.toUpperCase()
                    );
                    return (
                      <ListItem
                        key={day.id}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid container>
                          {/* Day Name */}
                          <Grid item xs={3}>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                color: templateDay?.off ? "gray" : "black",
                              }}
                            >
                              {day.weekday.slice(0, 3)}
                            </Typography>
                          </Grid>

                          {/* Time Information */}
                          <Grid item xs={7}>
                            <Typography
                              sx={{
                                color: templateDay?.off ? "gray" : "black",
                              }}
                            >
                              {templateDay
                                ? templateDay.off
                                  ? "OFF"
                                  : templateDay.startTimeAndEndTimeDTOS.map(
                                      (time, index) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px",
                                          }}
                                        >
                                          <div
                                            key={index}
                                            style={{
                                              border: "1px solid black",
                                              borderRadius: "8px",
                                              padding: "4px 10px",
                                              marginTop : "2px" ,
                                              marginRight : "6px" ,
                                              display: "inline-block",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {formatTime(
                                              time.startTime.hour,
                                              time.startTime.minute
                                            )}{" "}
                                            -{" "}
                                            {formatTime(
                                              time.endTime.hour,
                                              time.endTime.minute
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )
                                : "OFF"}
                            </Typography>
                          </Grid>

                          {/* Action Button */}
                          <Grid item xs={2}>
                            <ButtonBase
                              onClick={() => handleDayClick(day)}
                              size="small"
                              sx={{
                                px: 1,
                                borderRadius: "1rem",
                                backgroundColor: "black",
                                color: "#fff",
                                ml: 0,
                              }}
                            >
                              Select
                            </ButtonBase>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
              <TextField
                size="small"
                label="Template Name Input"
                fullWidth
                required
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "black",
                    },
                  },
                }}
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />

              <Button
                fullWidth
                className="sign-in-button"
                variant="contained"
                sx={{ mt: 2, mb: 4 }}
                onClick={handleSave}
              >
                SAVE
              </Button>
              {/* Error Dialog */}
              <Dialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                PaperProps={{
                  sx: {
                    borderRadius: "8px",
                    // padding: "10px",
                    width: "500px",
                    textAlign: "center",
                  },
                }}
              >
                <DialogContent
                  sx={{
                    textAlign: "center",
                    // padding: "2rem",
                  }}
                >
                  <DialogTitle
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    {" "}
                    <Typography variant="h5">
                      <b>TEMPLATE NAME IS MANDATORY</b>
                    </Typography>
                  </DialogTitle>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      width: "100%",
                      padding: "10px",
                      "&:hover": {
                        backgroundColor: "red",
                      },
                    }}
                    onClick={handleErrorDialogClose}
                  >
                    OK
                  </Button>
                </DialogContent>
              </Dialog>
            </Box>
          </Box>
        </div>
      </Container>

      {/* Dialog Box */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            // padding: "10px",
            width: "500px",
            textAlign: "center",
          },
        }}
      >
        <DialogContent
          sx={{
            textAlign: "center",
            padding: "2rem",
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            <b>WEEKLY TEMPLATE CREATED SUCCESSFULLY</b>
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              color: "white",
              width: "100%",
              padding: "10px",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
            onClick={handleDialogClose}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WeeklyTemplate;
