import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Shared/Header";
import { useLocation, useNavigate } from "react-router";
import TimePicker from "rc-time-picker";
import moment from "moment";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import Dailog from "../Shared/Dailog";
import AlertDialogSlide from "../Shared/Dailog";
import { useDispatch, useSelector } from "react-redux";
import { setTemplate } from "../../redux/actions";

const WeeklySet = ({ classes }) => {
  const [dispatchTime, setDispatchTime] = useState();
  const [dispatchEndTime, setDispatchEndTime] = useState();
  const [dailyTemplates, setDailyTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [errorTime, setErrorTime] = useState(false);
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const dispatch = useDispatch();
  const weekTemplateData = useSelector((state) => state.template);
  const history = useNavigate();
  const location = useLocation();
  const format = "hh:mm A";
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId") || "defaultVenueId";
  const [additionalFields, setAdditionalFields] = useState([]);
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          console.error("No access token found in localStorage");
          return;
        }
        const response = await axios.get(
          requests.getTemplate + "?" + `venueId=${venueId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          const data = response.data.data;
          setDailyTemplates(data.daily || []);
        }
      } catch (error) {
        if (error.response) {
          console.error("Error response:", error.response.data);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    };
    fetchTemplates();
  }, [venueId]);

  const handleTemplateChange = (event) => {
    const selectedTemplateId = event.target.value;
    setSelectedTemplate(selectedTemplateId);

    const template = dailyTemplates.find((t) => t.id === selectedTemplateId);
    if (template?.scheduleTemplateDetailDTOS?.length > 0) {
      const timeData =
        template.scheduleTemplateDetailDTOS[0]?.startTimeAndEndTimeDTOS?.[0];

      if (timeData?.startTime && timeData?.endTime) {
        setDispatchTime(
          moment()
            .hour(timeData.startTime.hour)
            .minute(timeData.startTime.minute)
        );
        setDispatchEndTime(
          moment().hour(timeData.endTime.hour).minute(timeData.endTime.minute)
        );
      } else {
        setDispatchTime(null);
        setDispatchEndTime(null);
      }
    } else {
      setDispatchTime(null);
      setDispatchEndTime(null);
    }
  };
  const handleAddMore = () => {
    setAdditionalFields((prevFields) => {
      const newField = {
        template: "",
        startTime: null,
        endTime: null,
      };
      return [...prevFields, newField];
    });
  };
  const handleTemplateChangeAdditional = (index, event) => {
    const selectedTemplateId = event.target.value;
    const template = dailyTemplates.find((t) => t.id === selectedTemplateId);

    if (template?.scheduleTemplateDetailDTOS?.length > 0) {
      const timePairs =
        template.scheduleTemplateDetailDTOS[0]?.startTimeAndEndTimeDTOS || [];

      if (timePairs.length > 0) {
        const selectedStartTime = moment()
          .hour(timePairs[0].startTime.hour)
          .minute(timePairs[0].startTime.minute);
        const selectedEndTime = moment()
          .hour(timePairs[0].endTime.hour)
          .minute(timePairs[0].endTime.minute);

        setAdditionalFields((prevFields) =>
          prevFields.map((field, i) =>
            i === index
              ? {
                  ...field,
                  template: selectedTemplateId,
                  startTime: selectedStartTime,
                  endTime: selectedEndTime,
                }
              : field
          )
        );
      }
    }
  };

  const handleStartTimeChangeAdditional = (index, value) => {
    setAdditionalFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[index].startTime = value;
      return newFields;
    });
  };
  const handleEndTimeChangeAdditional = (index, value) => {
    setAdditionalFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[index].endTime = value;
      return newFields;
    });
  };
  const handleSubmit = (event) => {
    const timeData = {
      id: `main-${Date.now()}`,
      dayOfWeek: location?.state?.day?.weekday, // Include weekday
      off: dispatchEndTime && dispatchTime ? false : true,
      startTimeAndEndTimeDTOS: [
        ...additionalFields.map((field, index) => ({
          id: `additional-${Date.now()}-${index}`,
          startTime: field.startTime
            ? {
                hour: field.startTime.hour(),
                minute: field.startTime.minute(),
              }
            : null,
          endTime: field.endTime
            ? {
                hour: field.endTime.hour(),
                minute: field.endTime.minute(),
              }
            : null,
        })),
        {
          id: `main-${Date.now()}`,
          startTime: dispatchTime
            ? {
                hour: dispatchTime.hour(),
                minute: dispatchTime.minute(),
              }
            : null,
          endTime: dispatchEndTime
            ? {
                hour: dispatchEndTime.hour(),
                minute: dispatchEndTime.minute(),
              }
            : null,
        },
      ],
    };
    dispatch(setTemplate.setTemplate(timeData));
    const venueId = searchParams.get("venueId") || "defaultVenueId";
    history(`/WeeklyTemplate?venueId=${venueId}`);
  };
  useEffect(() => {
    const currentWeekday = location?.state?.day?.weekday;
    if (currentWeekday && weekTemplateData?.length > 0) {
      const matchedData = weekTemplateData.find(
        (item) => item.weekday === currentWeekday
      );
      if (matchedData) {
        setDispatchTime(moment(matchedData.startTime, "hh:mm A"));
        setDispatchEndTime(moment(matchedData.endTime, "hh:mm A"));
      }
    }
  }, [location?.state?.day?.weekday, weekTemplateData]);

  return (
    <div className="signin-body">
      <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
        <CssBaseline />
        <div className="main-box">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Header
              menuList={[
                { menuItem: "Logout", handle: () => history("/admin/") },
              ]}
              heading={"WEEKLY SCHEDULE"}
            />
            {handlePopUp && (
              <Dailog
                handleCancel={() => setHandlePopUp(false)}
                errorDailog={popUpMessage}
              />
            )}
            {errorTime && (
              <AlertDialogSlide
                handleCancel={() => setErrorTime(false)}
                errorTime={errorTime}
              />
            )}
            <Box style={classes?.boxWidth}>
              <Typography
                variant="h6"
                align="center"
                sx={{ color: "red", my: 3 }}
              >
                {location?.state?.day?.weekday}
              </Typography>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "8px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "fit-content",
                  marginBottom: "10px",
                }}
              >
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                  <InputLabel id="template-select-label">
                    Select Daily Template
                  </InputLabel>
                  <Select
                    labelId="template-select-label"
                    value={selectedTemplate}
                    label="Select Daily Template"
                    onChange={handleTemplateChange}
                  >
                    {dailyTemplates.map((template) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.scheduleTemplateDetailDTOS?.[0]
                          ?.startTimeAndEndTimeDTOS?.[0]?.startTime &&
                        template.scheduleTemplateDetailDTOS?.[0]
                          ?.startTimeAndEndTimeDTOS?.[0]?.endTime
                          ? `${moment()
                              .hour(
                                template.scheduleTemplateDetailDTOS[0]
                                  .startTimeAndEndTimeDTOS[0].startTime.hour
                              )
                              .minute(
                                template.scheduleTemplateDetailDTOS[0]
                                  .startTimeAndEndTimeDTOS[0].startTime.minute
                              )
                              .format("hh:mm A")} - ${moment()
                              .hour(
                                template.scheduleTemplateDetailDTOS[0]
                                  .startTimeAndEndTimeDTOS[0].endTime.hour
                              )
                              .minute(
                                template.scheduleTemplateDetailDTOS[0]
                                  .startTimeAndEndTimeDTOS[0].endTime.minute
                              )
                              .format("hh:mm A")}`
                          : "No Time Available"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="time-box">
                  <TimePicker
                    value={dispatchTime}
                    onChange={(value) => setDispatchTime(value)}
                    showSecond={false}
                    allowEmpty
                    minuteStep={5}
                    placeholder="hh:mm"
                    format={format}
                    use12Hours
                    sx={{ mt: 2, mb: 2 }}
                    className="timeInput"
                    id="broadcastStartTime"
                  />
                  <label htmlFor="broadcastStartTime">
                    Broadcast Start Time
                  </label>
                </div>
                <div className="time-box">
                  <TimePicker
                    value={dispatchEndTime}
                    onChange={(value) => setDispatchEndTime(value)}
                    minuteStep={5}
                    allowEmpty
                    showSecond={false}
                    placeholder="hh:mm"
                    format={format}
                    use12Hours
                    sx={{ mt: 2, mb: 2 }}
                    className="timeInput"
                    id="broadcastEndTime"
                  />
                  <label htmlFor="broadcastEndTime">Broadcast End Time</label>
                </div>
              </div>

              {additionalFields.map((field, index) => (
                <div key={index}>
                  <div
                    style={{
                      border: "1px solid black",
                      borderRadius: "8px",
                      padding: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "fit-content",
                    }}
                  >
                    <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                      <InputLabel id={`template-select-label-${index}`}>
                        Select Daily Template
                      </InputLabel>
                      <Select
                        labelId={`template-select-label-${index}`}
                        value={field.template}
                        label="Select Daily Template"
                        onChange={(event) =>
                          handleTemplateChangeAdditional(index, event)
                        }
                      >
                        {dailyTemplates.map((template) => {
                          const timePairs =
                            template.scheduleTemplateDetailDTOS[0]
                              ?.startTimeAndEndTimeDTOS || [];

                          if (timePairs.length > 0) {
                            const formattedTime = `${moment()
                              .hour(timePairs[0].startTime.hour)
                              .minute(timePairs[0].startTime.minute)
                              .format("hh:mm A")} - ${moment()
                              .hour(timePairs[0].endTime.hour)
                              .minute(timePairs[0].endTime.minute)
                              .format("hh:mm A")}`;

                            return (
                              <MenuItem key={template.id} value={template.id}>
                                {formattedTime}
                              </MenuItem>
                            );
                          } else {
                            return (
                              <MenuItem key={template.id} value={template.id}>
                                No Time Available
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                    {/* Time pickers for start and end time */}
                    <div className="time-box">
                      <TimePicker
                        value={field.startTime}
                        onChange={(value) =>
                          handleStartTimeChangeAdditional(index, value)
                        }
                        showSecond={false}
                        allowEmpty
                        minuteStep={5}
                        placeholder="hh:mm"
                        format="hh:mm A"
                        use12Hours
                        sx={{ mt: 2, mb: 2 }}
                        className="timeInput"
                        id={`broadcastStartTime-${index}`}
                      />
                      <label htmlFor={`broadcastStartTime-${index}`}>
                        Broadcast Start Time
                      </label>
                    </div>

                    <div className="time-box">
                      <TimePicker
                        value={field.endTime}
                        onChange={(value) =>
                          handleEndTimeChangeAdditional(index, value)
                        }
                        minuteStep={5}
                        allowEmpty
                        showSecond={false}
                        placeholder="hh:mm"
                        format="hh:mm A"
                        use12Hours
                        sx={{ mt: 2, mb: 2 }}
                        className="timeInput"
                        id={`broadcastEndTime-${index}`}
                      />
                      <label htmlFor={`broadcastEndTime-${index}`}>
                        Broadcast End Time
                      </label>
                    </div>
                  </div>
                </div>
              ))}
              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={handleAddMore}
              >
                More Broadcasts
              </Button>
              <Button
                className="sign-in-button"
                fullWidth
                variant="contained"
                sx={{ mt: 0, mb: 4 }}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            </Box>
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default WeeklySet;
