import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate as useHistory, useLocation } from "react-router-dom";
import CONSTANTS from "../../constants";
import Dailog from "../Shared/Dailog";
import Header from "../Shared/Header";
import axios from "../Shared/axios";
import requests from "../Shared/requests";
import "../Signin.scss";

const format = "hh:mm A";
const heading = "WEEKLY BROADCAST";
// var moment = require("moment-timezone");
let now;
let startTime;

const WeeklyBroadcast = (props) => {
  const location = useLocation();
  const { classes } = props;
  const [shedulerValues, setShedulerValues] = useState({
    broadcastName: "",
  });
  const [popUpMessage, setPopUpMessage] = useState();
  const [handlePopUp, setHandlePopUp] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();
  const [errorDailog, setErrorDailog] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [dispatchTime, setDispatchTime] = useState();
  const [dispatchEndTime, setDispatchEndTime] = useState();
  const [scheduleData, setScheduleData] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId") || "defaultVenueId";
  const templateId = searchParams.get("templateId");
  const timezone = localStorage.getItem("timezone");

  useEffect(() => {
    newBroad();
    fetchScheduleData();
  }, []);

  const newBroad = async () => {
    try {
      const { data } = await axios.get(
        requests.timeShedular + "?" + `timezone=${timezone}`
      );
      now = moment(data?.data?.date + " " + data?.data?.endTime);
      startTime = moment(data?.data?.date + " " + data?.data?.startTime);
      setShedulerValues({
        broadcastName: "",
      });
      setStartDate(new Date(data?.data?.date + " " + data?.data?.startTime));
      setDispatchTime(startTime);
      setDispatchEndTime(now);
    } catch (error) {
      if (error.response.status === 403) {
        handleLogout();
      }
      console.log(error);
    }
  };

  const fetchScheduleData = async () => {
    try {
      const { data } = await axios.get(
        requests.getWeeklyTemplate + "/" + `${templateId}`
      );
      const scheduleList = data?.data?.scheduleTemplateDetailDTOS || [];
      setScheduleData(scheduleList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShedulerValues((preValue) => {
      return { ...preValue, [name]: value };
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };

  const handleCancel = () => {
    setErrorDailog(false);
  };

  const menuList = [
    {
      menuItem: "Logout",
      handle: handleLogout,
    },
  ];
  const handleCloseSuccess = () => {
    setSuccessMessage("");
    history(`/Broadcasts?venueId=${venueId}`, {
      state: { detail: location?.state?.detail },
    });
  };
  const handleDateChange = (value) => {
    setStartDate(value);
  };

  const handleClosePopup = () => {
    setHandlePopUp(false);
  };
  const formatTime = (hour, minute) => {
    if (hour === undefined || minute === undefined) return "OFF";

    const timeString = `${hour}:${minute}`;
    const time = moment(timeString, "HH:mm");
    return time.format("hh:mm A");
  };
  const ApiFormatTime = (hour, minute) => {
    if (hour === undefined || minute === undefined) return "OFF";

    const timeString = `${hour}:${minute}`;
    return timeString;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!shedulerValues.broadcastName) {
      setErrorDialogOpen(true);
      return;
    }

    const startDateFormatted = moment(startDate).format("YYYY-MM-DD");

    const startAndEndTimeDTOS = scheduleData
      .map((day) => {
        if (day.off) {
          return null;
        }
        const startTimeFormatted = moment(
          `${startDateFormatted} ${ApiFormatTime(
            day.startTime?.hour,
            day.startTime?.minute
          )}`
        ).format("HH:mm");

        const endTimeFormatted = moment(
          `${startDateFormatted} ${ApiFormatTime(
            day.endTime?.hour,
            day.endTime?.minute
          )}`
        ).format("HH:mm");

        return {
          dayOfWeek: day.dayOfWeek,
          startDateTime: startTimeFormatted,
          endDateTime: endTimeFormatted,
        };
      })
      .filter(Boolean);
    const payload = {
      eventName: shedulerValues.broadcastName,
      // startAndEndTimeDTOS: startAndEndTimeDTOS,
      templateId: templateId,
      venueId: venueId,
      startDate: startDateFormatted,
    };

    try {
      const response = await axios.post(requests.createWeeklySchedule, payload);
      console.log("API response:", response.data);
      setSuccessMessage("BROADCAST CREATED SUCCESSFULLY");
    } catch (error) {
      console.error("Error submitting schedule:", error);
      setPopUpMessage(error.response?.data?.message || "An error occurred");
      setHandlePopUp(true);
    }
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };
  return (
    <>
      <div className="signin-body">
        <Container component="main" maxWidth="xs" sx={{ color: "black" }}>
          <CssBaseline />
          <div className="main-box">
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Header menuList={menuList} heading={heading} />
              {handlePopUp ? (
                <Dailog
                  handleCancel={handleClosePopup}
                  errorDailog={popUpMessage}
                />
              ) : (
                " "
              )}
              {successMessage ? (
                <Dailog
                  handleCancel={handleCloseSuccess}
                  errorDailog={successMessage}
                />
              ) : (
                " "
              )}
              <Box
                component="form"
                AM
                style={classes.boxWidth}
                noValidate
                fullWidth
                sx={{ mt: 1 }}
              >
                {errorDailog ? (
                  <Dailog
                    handleCancel={handleCancel}
                    errorDailog={errorDailog}
                  />
                ) : (
                  " "
                )}
                <div className="customer-detail">
                  <Typography className="detail">
                    <b>Venue ID :</b> {venueId}
                  </Typography>
                </div>
                <TextField
                  margin="normal"
                  required
                  id="broadcastName"
                  label="Broadcast Name Input"
                  name="broadcastName"
                  autoFocus
                  fullWidth
                  value={shedulerValues.broadcastName}
                  size="small"
                  color="error"
                  onChange={handleChange}
                  error={shedulerValues.broadcastName === ""}
                />

                <div className="time-date-box">
                  <label htmlFor="broadcastStartTime">Broadcast Date</label>
                  <input type="text" id="broadcastStartTime" />
                </div>
                <DatePicker
                  className="datePicker"
                  dateFormat="MMM-dd-yyyy"
                  selected={startDate}
                  onChange={(value) => handleDateChange(value)}
                />
                <Box
                  sx={{
                    border: 1,
                    borderRadius: "1rem",
                    borderColor: "gray",
                    p: 2,
                  }}
                >
                  {scheduleData?.map((day) => (
                    <Box key={day.id} sx={{ mb: 2 }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          color: day.off ? "gray" : "black",
                        }}
                      >
                        {day.dayOfWeek.substring(0, 3)}
                      </Typography>
                      {day.off ? (
                        <Typography
                          sx={{
                            color: "gray",
                            textAlign: "right",
                            marginTop: "-1.5rem",
                          }}
                        >
                          OFF
                        </Typography>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "flex-end",
                            marginTop: "-1.5rem",
                          }}
                        >
                          {day.startTimeAndEndTimeDTOS.map((game) => (
                            <Typography
                              key={game.id}
                              sx={{
                                ml: 2,
                                mt : 1,
                                border: "1px solid black",
                                borderRadius: "8px",
                                padding: "4px 8px",
                                display: "inline-block",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              {formatTime(
                                game.startTime?.hour,
                                game.startTime?.minute
                              )}{" "}
                              -{" "}
                              {formatTime(
                                game.endTime?.hour,
                                game.endTime?.minute
                              )}
                            </Typography>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>

                <Button
                  className="sign-in-button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 1 }}
                  type="button"
                  onClick={handleSubmit}
                >
                  SAVE
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
        <Dialog
          open={errorDialogOpen}
          onClose={handleErrorDialogClose}
          PaperProps={{
            sx: {
              borderRadius: "8px",
              width: "500px",
              textAlign: "center",
            },
          }}
        >
          <DialogContent>
            <DialogTitle
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <Typography variant="h5">
                <b>BROADCAST NAME IS MANDATORY</b>
              </Typography>
            </DialogTitle>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                color: "white",
                width: "100%",
                padding: "10px",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              onClick={handleErrorDialogClose}
            >
              OK
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default WeeklyBroadcast;
